import { Button, Card, Col, Form, Input, message, Popconfirm, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import vod from "../../../../../apis/vod";
import { showContact, showHowToBuy } from "../../../../../theme-variables";
import "../../managers.scss";

class PagesSettings extends React.Component {
  state = {
    loading_tos: false,
    reject_tos_success: false,
    policy_content_changed: false,
    regulations_content_changed: false,
    contact_content_changed: false,
    htb_content_changed: false,
    policy_content: "",
    regulations_content: "",
    contact_content: "",
    htb_content: "",
    policy_content_error: "",
    regulations_content_error: "",
    contact_content_error: "",
    htb_content_error: "",
  };
  changePageContent = (pageSlug) => {
    switch (pageSlug) {
      case "policy":
        this.props
          .changePageContent(1, {
            title: "Polityka prywatności",
            content: this.state.policy_content,
            slug: "polityka",
          })
          .then((res) => {
            this.setState({ policy_content_changed: false });
          })
          .catch((err) => {
            let contentError =
              "Wystąpił błąd. Prosimy o kontakt z developerem.";
            if (err.response.data && err.response.data.message)
              contentError = err.response.data.message;
            if (err.response.data && err.response.data.formError)
              contentError = err.response.data.formError.content;
            this.setState({
              policy_content_error: contentError,
              policy_content_changed: false,
            });
          });
        break;
      case "regulations":
        this.props
          .changePageContent(2, {
            title: "Regulamin serwisu",
            content: this.state.regulations_content,
            slug: "regulamin",
          })
          .then((res) => {
            this.setState({ regulations_content_changed: false });
          })
          .catch((err) => {
            let contentError =
              "Wystąpił błąd. Prosimy o kontakt z developerem.";
            if (err.response.data && err.response.data.message)
              contentError = err.response.data.message;
            if (err.response.data && err.response.data.formError)
              contentError = err.response.data.formError.content;
            this.setState({
              regulations_content_error: contentError,
              regulations_content_changed: false,
            });
          });
        break;
      case "contact":
        this.props
          .changePageContent(3, {
            title: "Deklaracja dostępności",
            content: this.state.contact_content,
            slug: "deklaracjadostepnosci",
          })
          .then((res) => {
            this.setState({ contact_content_changed: false });
          })
          .catch((err) => {
            let contentError =
              "Wystąpił błąd. Prosimy o kontakt z developerem.";
            if (err.response.data && err.response.data.message)
              contentError = err.response.data.message;
            if (err.response.data && err.response.data.formError)
              contentError = err.response.data.formError.content;
            this.setState({
              contact_content_error: contentError,
              contact_content_changed: false,
            });
          });
        break;
      case "htb":
        this.props
          .changePageContent(4, {
            title: "Jak kupić bilet grupowy?",
            content: this.state.htb_content,
            slug: "jakkupicbilet",
          })
          .then((res) => {
            this.setState({ htb_content_changed: false });
          })
          .catch((err) => {
            let contentError =
              "Wystąpił błąd. Prosimy o kontakt z developerem.";
            if (err.response.data && err.response.data.message)
              contentError = err.response.data.message;
            if (err.response.data && err.response.data.formError)
              contentError = err.response.data.formError.content;
            this.setState({
              htb_content_error: contentError,
              htb_content_changed: false,
            });
          });
        break;
      default:
        break;
    }
  };
  rejectTosForAllUsers = () => {
    this.setState({ loading_tos: true });
    vod
      .post(
        `/api/users/reject-tos`,
        {},
        {
          headers: {
            "x-auth-token": this.props.token,
          },
        }
      )
      .then((res) => {
        this.setState({
          reject_tos_success: true,
          loading_tos: false,
        });
        message.success("Sukces");
      })
      .catch((err) => {
        message.error("Wystąpił błąd: " + err);
      });
  };
  componentDidMount() {
    this.props
      .getPage("polityka")
      .then((res) => {
        this.setState({
          policy_content: res.data.page.content,
          policy_content_changed: false,
          policy_content_error: "",
        });
      })
      .catch((err) => {
        this.setState({
          policy_content_error:
            "Wystąpił błąd przy pobieraniu zawartości strony. Prosimy skontaktować się z administratorem",
        });
      });

    this.props
      .getPage("regulamin")
      .then((res) => {
        this.setState({
          regulations_content: res.data.page.content,
          regulations_content_changed: false,
          regulations_content_error: "",
        });
      })
      .catch((err) => {
        this.setState({
          regulations_content_error:
            "Wystąpił błąd przy pobieraniu zawartości strony. Prosimy skontaktować się z administratorem",
        });
      });

    this.props
      .getPage("deklaracjadostepnosci")
      .then((res) => {
        this.setState({
          contact_content: res.data.page.content,
          contact_content_changed: false,
          contact_content_error: "",
        });
      })
      .catch((err) => {
        this.setState({
          contact_content_error:
            "Wystąpił błąd przy pobieraniu zawartości strony. Prosimy skontaktować się z administratorem",
        });
      });

    this.props
      .getPage("jakkupicbilet")
      .then((res) => {
        this.setState({
          htb_content: res.data.page.content,
          htb_content_changed: false,
          htb_content_error: "",
        });
      })
      .catch((err) => {
        this.setState({
          htb_content_error:
            "Wystąpił błąd przy pobieraniu zawartości strony. Prosimy skontaktować się z administratorem",
        });
      });
  }
  render() {
    return (
      <Card
        style={{ marginBottom: "30px" }}
        title="Zarządzaj zawartością stron"
      >
        <Row>
          <Col>
            <p>
              Wciśnięcie poniższego przycisku spowoduje wyświetlenie informacji
              o nowym regulaminie na stronie głównej. Użytkownik będzie musiał
              zaakceptować nowy regulamin oraz politykę prywatności, aby móc
              korzystać ze strony.
            </p>
            <Popconfirm
              title="Czy na pewno chcesz to zrobić?"
              onConfirm={this.rejectTosForAllUsers}
            >
              <Button
                type="primary"
                style={{ marginBottom: 25 }}
                disabled={this.state.reject_tos_success}
              >
                Poinformuj użytkowników o nowym regulaminie
              </Button>
            </Popconfirm>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12}>
            <h3>
              <b>Polityka prywatności</b>
            </h3>
            <Form layout="vertical" style={{ maxWidth: "calc(100% - 30px)" }}>
              <Form.Item label="Zawartość strony (kod html)">
                <Input.TextArea
                  name="policy_content"
                  value={this.state.policy_content}
                  onChange={(e) => {
                    this.setState({
                      policy_content: e.target.value,
                      policy_content_error: "",
                      policy_content_changed: true,
                    });
                  }}
                  style={{
                    minHeight: 300,
                  }}
                />
                <div style={{ color: "#cf3d3d", padding: "10px 5px" }}>
                  {this.state.policy_content_error}
                </div>
                <Button
                  type="primary"
                  onClick={() => this.changePageContent("policy")}
                  disabled={!this.state.policy_content_changed}
                >
                  Zapisz
                </Button>
                <Link to={`/n/polityka`}>
                  <Button type="secondary" style={{ marginLeft: 15 }}>
                    Zobacz
                  </Button>
                </Link>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} md={12}>
            <h3>
              <b>Regulamin serwisu</b>
            </h3>
            <Form layout="vertical" style={{ maxWidth: "calc(100% - 30px)" }}>
              <Form.Item label="Zawartość strony (kod html)">
                <Input.TextArea
                  name="regulations_content"
                  value={this.state.regulations_content}
                  onChange={(e) => {
                    this.setState({
                      regulations_content: e.target.value,
                      regulations_content_error: "",
                      regulations_content_changed: true,
                    });
                  }}
                  style={{
                    minHeight: 300,
                  }}
                />
                <div style={{ color: "#cf3d3d", padding: "10px 5px" }}>
                  {this.state.regulations_content_error}
                </div>
                <Button
                  type="primary"
                  onClick={() => this.changePageContent("regulations")}
                  disabled={!this.state.regulations_content_changed}
                >
                  Zapisz
                </Button>
                <Link to={`/n/regulamin`}>
                  <Button type="secondary" style={{ marginLeft: 15 }}>
                    Zobacz
                  </Button>
                </Link>
              </Form.Item>
            </Form>
          </Col>
          {showContact ? (
            <Col xs={24} md={12}>
              <h3>
                <b>Deklaracja dostępności</b>
              </h3>
              <Form layout="vertical" style={{ maxWidth: "calc(100% - 30px)" }}>
                <Form.Item label="Zawartość strony (kod html)">
                  <Input.TextArea
                    name="contact_content"
                    value={this.state.contact_content}
                    onChange={(e) => {
                      this.setState({
                        contact_content: e.target.value,
                        contact_content_error: "",
                        contact_content_changed: true,
                      });
                    }}
                    style={{
                      minHeight: 300,
                    }}
                  />
                  <div style={{ color: "#cf3d3d", padding: "10px 5px" }}>
                    {this.state.contact_content_error}
                  </div>
                  <Button
                    type="primary"
                    onClick={() => this.changePageContent("contact")}
                    disabled={!this.state.contact_content_changed}
                  >
                    Zapisz
                  </Button>
                  <Link to={`/n/kontakt`}>
                    <Button type="secondary" style={{ marginLeft: 15 }}>
                      Zobacz
                    </Button>
                  </Link>
                </Form.Item>
              </Form>
            </Col>
          ) : null}
          {showHowToBuy ? (
            <Col xs={24} md={12}>
              <h3>
                <b>Jak kupić bilet?</b>
              </h3>
              <Form layout="vertical" style={{ maxWidth: "calc(100% - 30px)" }}>
                <Form.Item label="Zawartość strony (kod html)">
                  <Input.TextArea
                    name="htb_content"
                    value={this.state.htb_content}
                    onChange={(e) => {
                      this.setState({
                        htb_content: e.target.value,
                        htb_content_error: "",
                        htb_content_changed: true,
                      });
                    }}
                    style={{
                      minHeight: 300,
                    }}
                  />
                  <div style={{ color: "#cf3d3d", padding: "10px 5px" }}>
                    {this.state.htb_content_error}
                  </div>
                  <Button
                    type="primary"
                    onClick={() => this.changePageContent("htb")}
                    disabled={!this.state.htb_content_changed}
                  >
                    Zapisz
                  </Button>
                  <Link to={`/n/jakkupicbilet`}>
                    <Button type="secondary" style={{ marginLeft: 15 }}>
                      Zobacz
                    </Button>
                  </Link>
                </Form.Item>
              </Form>
            </Col>
          ) : null}
        </Row>
      </Card>
    );
  }
}

export default PagesSettings;
