export const shortVodUrl = "vod.teatr-polski.pl";
export const vodApiUrl =
  process.env.NODE_ENV === "production"
    ? "https://www.vod.teatr-polski.pl"
    : "http://localhost:8000";
export const miniNavbarHorizontal = true;
export const showFaq = false;
export const showContact = true;
export const showHowToBuy = true;
export const showSearchbar = true;
export const sliderAutoplay = true;
export const slidesTransition = 500;
export const changeSlideTime = 3000;
export const sliderStyle = 2;
export const funcNewContentEmail = false;
export const minAgeForSignUp = 13;
export const authFooterVariant = 1;
export const reverseVodEventsOrder = false;
export const vodText = "VOD";
export const repertoireText = "Repertuar";
export const giftText = "Zrób prezent";
export const vodTextEn = "VOD";
export const repertoireTextEn = "Repertoire";
export const giftTextEn = "Make a gift";
export const noAuthRedirect = "/strona-glowna";
export const multipleLanguages = true;
export const languages = ["pl", "en"];
export const defaultLanguage = "pl";
export const version = "1.10";
export const updateDate = "11 kwietnia 2023";
export const additionalUrlText = "";
export const additionalUrl = "";
export const maxVideoUploadWeight = 10;
export const fetchAddresses = false;
export const allowInvoices = true;
export const allowCompanyInvoices = true;
export const paymentCheckbox =
  "Akceptuję <a href='/regulamin' target='_blank' rel='noopener noreferrer'>regulamin serwisu</a>";
export const paymentCheckboxEn =
  "Accept <a href='/regulamin' target='_blank' rel='noopener noreferrer'>regulations</a>";
export const paymentVatInfo = "w tym podatek VAT";
export const paymentVatInfoEn = "including VAT";

export const maintenanceImgHeight = 256;
export const maintenanceSign = "Zespół Teatru Polskiego w Poznaniu";
export const maintenanceSignEn = "Team Teapp";
export const maintenanceLogoInsteadOfSign = true;
export const maintenanceSignUrl = "";

export const facebookPixel = null;
export const googleAnalytics = "";
export const googleTagManager = "";

export const imageServerUrl = "";
